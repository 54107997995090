/* DEPARTMENT */
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';
export const DEPARTMENT_LIST_FAILED = 'DEPARTMENT_LIST_FAILED';

export const DEPARTMENT_ADD = 'DEPARTMENT_ADD';
export const DEPARTMENT_ADD_SUCCESS = 'DEPARTMENT_ADD_SUCCESS';
export const DEPARTMENT_ADD_FAILED = 'DEPARTMENTADD_ADD_FAILED';

export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';

export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAILED = 'DEPARTMENTADD_DELETE_FAILED';
