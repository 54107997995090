/* LEAVEREQUEST */
export const LEAVEREQUEST_LIST = 'LEAVEREQUEST_LIST';
export const LEAVEREQUEST_LIST_SUCCESS = 'LEAVEREQUEST_LIST_SUCCESS';
export const LEAVEREQUEST_LIST_FAILED = 'LEAVEREQUEST_LIST_FAILED';

export const LEAVEREQUEST_ADD = 'LEAVEREQUEST_ADD';
export const LEAVEREQUEST_ADD_SUCCESS = 'LEAVEREQUEST_ADD_SUCCESS';
export const LEAVEREQUEST_ADD_FAILED = 'LEAVEREQUESTADD_ADD_FAILED';

export const LEAVEREQUEST_UPDATE = 'LEAVEREQUEST_UPDATE';
export const LEAVEREQUEST_UPDATE_SUCCESS = 'LEAVEREQUEST_UPDATE_SUCCESS';
export const LEAVEREQUEST_UPDATE_FAILED = 'LEAVEREQUEST_UPDATE_FAILED';

export const LEAVEREQUEST_DELETE = 'LEAVEREQUEST_DELETE';
export const LEAVEREQUEST_DELETE_SUCCESS = 'LEAVEREQUEST_DELETE_SUCCESS';
export const LEAVEREQUEST_DELETE_FAILED = 'LEAVEREQUESTADD_DELETE_FAILED';

export const LEAVEREQUEST_TYPE_LIST = 'LEAVEREQUEST_TYPE_LIST';
export const LEAVEREQUEST_TYPE_LIST_SUCCESS = 'LEAVEREQUEST_TYPE_LIST_SUCCESS';
export const LEAVEREQUEST_TYPE_LIST_FAILED = 'LEAVEREQUEST_TYPE_LIST_FAILED';
