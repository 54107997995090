/* WORKSHEET */
export const TICKET_LIST = "TICKET_LIST";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_LIST_FAILED = "TICKET_LIST_FAILED";

export const TICKET_UPDATE = "TICKET_UPDATE";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAILED = "TICKET_UPDATE_FAILED";

export const TICKET_DATA = "TICKET_DATA";
export const TICKET_DATA_SUCCESS = "TICKET_DATA_SUCCESS";
export const TICKET_DATA_FAILED = "TICKET_DATA_FAILED";


export const SKILL_LIST = "SKILL_LIST";
export const SKILL_LIST_SUCCESS = "SKILL_LIST_SUCCESS";
export const SKILL_LIST_FAILED = "SKILL_LIST_FAILED";

export const SKILL_UPDATE = "SKILL_UPDATE";
export const SKILL_UPDATE_SUCCESS = "SKILL_UPDATE_SUCCESS";
export const SKILL_UPDATE_FAILED = "SKILL_UPDATE_FAILED";

export const DAILY_REPORT_LIST = "DAILY_REPORT_LIST";
export const DAILY_REPORT_LIST_SUCCESS = "DAILY_REPORT_LIST_SUCCESS";
export const DAILY_REPORT_LIST_FAILED = "DAILY_REPORT_LIST_FAILED";

export const DAILY_REPORT_ADD = "DAILY_REPORT_ADD";
export const DAILY_REPORT_ADD_SUCCESS = "DAILY_REPORT_ADD_SUCCESS";
export const DAILY_REPORT_ADD_FAILED = "DAILY_REPORT_ADD_FAILED";

export const WEEKLY_REPORT_LIST = "WEEKLY_REPORT_LIST";
export const WEEKLY_REPORT_LIST_SUCCESS = "WEEKLY_REPORT_LIST_SUCCESS";
export const WEEKLY_REPORT_LIST_FAILED = "WEEKLY_REPORT_LIST_FAILED";

export const WEEKLY_REPORT_ADD = "WEEKLY_REPORT_ADD";
export const WEEKLY_REPORT_ADD_SUCCESS = "WEEKLY_REPORT_ADD_SUCCESS";
export const WEEKLY_REPORT_ADD_FAILED = "WEEKLY_REPORT_ADD_FAILED";

export const MONTHLY_REPORT_LIST = "MONTHLY_REPORT_LIST";
export const MONTHLY_REPORT_LIST_SUCCESS = "MONTHLY_REPORT_LIST_SUCCESS";
export const MONTHLY_REPORT_LIST_FAILED = "MONTHLY_REPORT_LIST_FAILED";

export const MONTHLY_REPORT_ADD = "MONTHLY_REPORT_ADD";
export const MONTHLY_REPORT_ADD_SUCCESS = "MONTHLY_REPORT_ADD_SUCCESS";
export const MONTHLY_REPORT_ADD_FAILED = "MONTHLY_REPORT_ADD_FAILED";

export const WORKSHEET_TICKET_LIST = "WORKSHEET_TICKET_LIST";
export const WORKSHEET_TICKET_LIST_SUCCESS = "WORKSHEET_TICKET_LIST_SUCCESS";
export const WORKSHEET_TICKET_LIST_FAILED = "WORKSHEET_TICKET_LIST_FAILED";

export const VIEW_REPORT = "VIEW_REPORT";
export const VIEW_REPORT_SUCCESS = "VIEW_REPORT_SUCCESS";
export const VIEW_REPORT_FAILED = "VIEW_REPORT_FAILED";
